<template>
  <card :title="$t('Импорт данных')">
     <load-block :load="wait"></load-block>
    <el-tabs
      :tab-position="tabPosition"
      v-model="nowTab"
      class="mb-2"
      @tab-click="tabClick"
    >
      <template v-for="item in tabConvert">
        <el-tab-pane :label="$t(item)" :name="item" :key="item">
          <div class="row" v-if="nowTab == item">
            <div class="col-6">
              <label for="file" class="label_main"> 
                <div class="form-group has-label">
                  <label>{{ $t("Выберите файл (*.xls ,*.json):")}}</label>
                  <div class="el-input">
                    <input v-if="!file"
                      type="file"
                      id="file"
                      name="file"
                      class="inpL"
                      accept=".xls,.xlsx,.json"
                      @change="previewFiles"
                    /> 
                    <div class="form-control def_file"> {{name}} <span class="close" @click="clearFile"> <i class="table_icon icon_delete"></i></span>      </div> 
                  </div></div
              ></label>
            </div>
            <div class="col-2">
              <label>&nbsp;</label>
              <l-button
                type="success"
                :disabled="!hasImport"
                wide
                block
                @click="loadFile"
                >{{ $t("Импортировать") }}</l-button
              >
            </div>
          </div>
        </el-tab-pane>
      </template>
    </el-tabs>
  </card>
</template>
<script>
//TODO отрефакторить этот код !!

import { Tabs, TabPane, Upload } from "element-ui";
import { SET_TAB_PAGE } from "./store/type";
import FileTablePage from "./FileTablePage";
import { api } from "../../../util/api";
import { API_METHODS, TYPE_IMPORT_FILE , TYPE_ERRORS} from "../../../constant";
  import LoadBlock from "../../shared/LoadBlock.vue";
  import notify from '../../../helpers/notify/notify'
export default {
  components: {
    ElTabs: Tabs,
    ElTabPane: TabPane,
    FileTablePage,
    Upload,
    LoadBlock
  },
  data() {
    return {
      tabPosition: "top",
      file: null,
      name:'',
      typeImportFile: TYPE_IMPORT_FILE,
      
      wait: false
    };
  },
  methods: {
    async loadFile() {
      this.wait = true;
      const paramsIn = {
        self: this,
        uploadedFile: this.file,
        SubjectType: this.nowTab
      };
      try {
        let res =  await api(API_METHODS.IMPORT_FILE, paramsIn, this.$store.state);
      
        if(res && res.warnings && res.warnings.length > 0) {
              let resmsg =  res.warnings.join("; ")
              notify(this, resmsg, TYPE_ERRORS.WARNING)
        }
       if(res && res.errors && res.errors.length == 0 && res.warnings.length == 0) {
                if(res.errors && res.errors.length  == 0){notify(this, this.$t("Успешно"),TYPE_ERRORS.SUCCESS); }
        }

        this.file = null
        this.name = null
        this.wait = false;
      } catch (err) {
        this.wait = false;
      }
    },
    previewFiles(event) {
      this.file = event.target.files[0];
      this.name = this.file.name
    },
    clearFile(event){
      event.preventDefault();
      this.file = null;
      this.name = ''
    },
    tabClick(){
      this.file = null;
      this.name = ''
    }
  },

  computed: {

    tabConvert(){
      return Object.values(this.typeImportFile)
    },

    nowTab: {
      set(tab) {
        this.file = null;
        this.$store.commit(SET_TAB_PAGE, tab);
      },
      get() {
        return this.$store.state.importFile.tabnow;
      }
    },
    hasImport() {
      return this.wait ? this.wait : this.file ? true : false;
    }
  }
};
</script>
